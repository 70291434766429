<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <Steps
        :levels="level"
        v-if="level && appStatus == 1"
        :hierarchy="hierarchy"
      />
      <div class="content-show">
        <van-tabs
          v-model="active"
          :animated="true"
          :class="appStatus == 1 ? 'disappear' : ''"
        >
          <van-tab title="详情页" name="a" class="">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="招标项目信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="招标编号" :value="details.biddingNo" />
                    <van-cell
                      title="招标项目名称"
                      :value="details.biddingProject"
                    />
                    <van-cell
                      title="招标代理机构"
                      :value="details.biddingAgency"
                    />
                    <van-cell title="招标人" :value="details.biddingUser" />
                    <van-cell title="招标方式" :value="details.biddingMethod">
                    </van-cell>
                    <van-cell
                      title="资审方式"
                      :value="details.qualificationMethod"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="招标失败信息" name="2" icon="label">
                  <van-cell-group class="group">
                    <van-cell
                      title="失败情况分类"
                      :value="details.failureTypeName"
                    />
                    <van-cell
                      title="招标失败原因"
                      :value="details.failureReason"
                    />
                    <van-cell title="附件">
                      <a
                        v-if="
                          details.suffix != 'rar' && details.suffix != 'zip'
                        "
                        :href="
                          '/api-ec/fileDownload/FileuploadAction/' +
                            details.fileId +
                            '-' +
                            details.fileName
                        "
                        >{{ details.fileName }}</a
                      >
                      <span v-else @click="onCompression">{{
                        details.fileName
                      }}</span>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="审批记录" name="c" v-if="appStatus != 1">
            <van-list :finished="true" finished-text="没有更多了">
              <div
                class="record"
                v-for="(item, index) in details.wfapproveinfoVOList"
                :key="index"
              >
                <van-cell-group>
                  <van-cell title="审批层级" :value="item.node"></van-cell>
                  <van-cell title="审批节点" :value="item.nodename"></van-cell>
                  <van-cell title="审批人" :value="item.userName"></van-cell>
                  <van-cell
                    title="审批时间"
                    :value="item.enddatetime"
                  ></van-cell>
                  <van-cell
                    title="审批结论"
                    :value="item.resultType"
                  ></van-cell>
                  <van-cell
                    title="审批意见"
                    :value="item.result"
                    v-if="item.resultType == '不同意'"
                  ></van-cell>
                </van-cell-group>
              </div>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: true,
      submitflag: false,
      show: true,
      showage: true,
      title: "招标失败审批详情",
      level: null,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      numberrows: "2",
      projectlist: [],
      hierarchy: "",
    };
  },
  computed: {
    bidfailureId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
    type() {
      return this.$route.query.type;
    },
    biddingPackageId() {
      return this.$route.query.biddingPackageId;
    },
  },
  created() {
    this.selectbiddingFailedViewInfos();
    // this.Projectlist()
  },
  methods: {
    async selectbiddingFailedViewInfos() {
      const params = {
        biddingPackageId: this.biddingPackageId,
        bidfailureId: this.bidfailureId,
        type: this.type,
      };
      let data = await this.$api.ec.selectbiddingFailedViewInfos(params);
      if (data) {
        this.loadingfalg = false;
      }
      data.wfnodeVOs.map((item, index) => {
        if (item.isNodeaction == 1) {
          this.level = index + 1;
          return;
        }
      });
      this.hierarchy = data.wfnodeVOs.length;
      this.details = data;
      if (this.details.fileName) {
        this.details.suffix = this.details.fileName.substring(
          this.details.fileName.lastIndexOf(".") + 1
        );
      }
      console.log(this.details);
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({
          name: "ecbiddingfailure",
        });
      } else {
        this.showage = true;
        this.title = "招标失败审批详情";
      }
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    onRefresh() {
      this.loading = true;
    },
    onLoad() {
      this.Projectlist();
    },
    ondown() {
      let query = {
        id: this.details.fileId,
        type: 2,
      };
      this.$router.push({ name: "preview", query });
    },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          bidfailureId: this.bidfailureId,
          auditResult: 0,
          approveOpinion: message,
        };
      } else {
        params = {
          bidfailureId: this.bidfailureId,
          auditResult: 1,
          approveOpinion: "同意",
        };
      }
      let data = await this.$api.ec.biddingFailedApprove(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ecbiddingfailure",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.record {
  margin-top: 10px;
}
</style>
